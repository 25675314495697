@font-face {
  font-family: "Rand";
  src: url("./Rand-Bold.woff2") format("woff2"),
    url("./Rand-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Rand";
  src: url("./Rand-Medium.woff2") format("woff2"),
    url("./Rand-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Rand";
  src: url("./Rand-Regular.woff2") format("woff2"),
    url("./Rand-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
