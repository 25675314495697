body {
  margin: 0;
  font-family: Rand;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Rand source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container {
  width: 100%;
  max-width: 780px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: fit-content !important;
}
.sub-container {
  min-width: 700px;
}
.skeleton-kbar-image {
  height: 100%;
  width: 130px;
  border-radius: 6px;
  background: #c7c7c7;
  margin-bottom: 6px !important;
  animation: shimmer 1.5s infinite;
}
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
.skeleton-text {
  height: 14px;
  width: 100%;
  min-width: 80px;
  margin-left: 16px;
  border-radius: 6px;
  background: #c7c7c7;
}

.skeleton-info-text {
  height: 14px;
  width: 100%;
  min-width: 200px;
  margin-left: 16px;
  border-radius: 6px;
  background: #c7c7c7;
  margin-bottom: 2px !important;
}
.skeleton-details-text {
  height: 22px;
  width: 100%;
  text-align: center;
  margin-left: 16px;
  border-radius: 6px;
  background: #c7c7c7;
  margin: 0 32px;
  margin-bottom: 6px !important;
}
.skeleton {
  margin: 0 1px 1px 0;
  border-radius: 6px;
  background: #c7c7c7;
  background-image: linear-gradient(
    90deg,
    #b3b3b3 5.5%,
    #b6b2b2 90.2%
  ) !important;
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1.5s ease infinite;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
.certificate-section {
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: fit-content !important;
  width: 99% !important;
  border: 1px solid #c7c7c7;
  margin: 0 auto !important;
}

.certificate-section2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4% 6% 0 4%;
  background-color: #e6e6e6;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.certificate-section2 .goldbar-image-section {
  width: 27%;
}

.certificate-section2 .goldbar-image-section img {
  width: 96% !important;
  max-height: 335px;
  max-width: 167px;
  margin-bottom: -45%;
  aspect-ratio: 2/4;
}

.certificate-section2 .goldbar-image-section p {
  width: 100%;
}

.certificate-section2 .certificate-card {
  width: 73%;
  padding-left: 2.5%;
}

.certificate-section2 .certificate-card h1 {
  font-size: 46.4px;
  margin: 0;
  color: #bfa670;
  font-weight: 400;
}

.certificate-section2 .certificate-card .info-section {
  display: flex !important;
  margin: 4% 0 3%;
}

.certificate-section2 .certificate-card .info-section .label-section {
  display: flex !important;
  flex-direction: column;
}

.certificate-section2 .certificate-card span {
  display: flex;
}

.certificate-section2 .certificate-card label {
  font-size: 19px;
  line-height: 27px;
  font-weight: 400;
  padding: 6.4px 0 0 0;
}

.certificate-section2 .certificate-card p {
  margin: 0;
  font-size: 19px;
  line-height: 27px;
  font-weight: 500;
  padding: 6.4px 0 0 0;
}

.certificate-section2 .certificate-card .line-separator {
  width: 1px;
  margin: 12px 32px 1.6px;
  background-color: #e1261c !important;
}

.owner-section {
  height: 10%;
  width: 100%;
  min-width: fit-content;
  max-width: 100%;
  padding: 2% 4% 3% 4%;
  box-sizing: border-box;
  display: flex !important;
  align-items: center;
  background-color: #ffffff;
}

.owner-section .owner-info-before {
  width: 27%;
}

.owner-section .owner-info-after {
  width: 73%;
  min-width: fit-content;
  box-sizing: border-box;
  display: flex !important;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 1.3%;
}

.owner-section .owner-info-after p {
  margin: 0;
  font-size: 12px;
  line-height: 21px;
}
.skeleton-title {
  width: 240px;
  height: 48px;
  margin: 2.4px 0 3.2px 0 !important;
}
.skeleton-text {
  width: 160px;
  height: 20px;
  padding: 6.4px 0 0 0 !important;
  margin: 7px 0 0 0 !important;
}
.skeleton-info-text {
  height: 14px;
  width: 100%;
  margin-right: 32px;
  border-radius: 6px;
  background: #c7c7c7;
  margin-bottom: 2px;
  height: 21px !important;
}
.skeleton-details-text {
  height: 22px;
  width: 100%;
  text-align: center;
  margin-left: 16px;
  border-radius: 6px;
  background: #c7c7c7;
  margin: 0 32px;
  margin-bottom: 6px !important;
}
.skeleton-kbar-image {
  border-radius: 6px;
  max-height: 335px;
  max-width: 167px;
  width: 96% !important;
  margin-bottom: -45% !important;
  aspect-ratio: 2/4;
  background: #c7c7c7;
}
.owner-id {
  font-weight: 700;
}
.skeleton {
  margin: 0 1px 1px 0;
  border-radius: 6px;
  background: #c7c7c7;
  background-image: linear-gradient(
    90deg,
    #b3b3b3 5.5%,
    #b6b2b2 90.2%
  ) !important;
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1.5s ease infinite;
}
.content-separator {
  width: 95%;
  color: #4d4d4d;
  border-bottom: 1px solid #00000021;
}
.declaimer-container {
  display: flex;
  padding: 12px 19px;
  .declaimer-title {
    font-weight: 700;
  }

  & p {
    margin: 0;
    color: #4d4d4d;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  & a {
    margin: 0;
    color: #4d4d4d;
    font-weight: 400;
    text-decoration: underline;
  }
}
@media screen and (min-width: 551px) and (max-width: 640px) {
  .container {
    align-items: start !important;
  }
  .certificate-section2 {
    .goldbar-image-section {
      & img {
        width: 83% !important;
      }
    }
  }
  .title {
    font-size: 35.2px !important;
  }
  .certificate-value {
    font-size: 15px !important;
    line-height: 20px !important;
  }
  label {
    font-size: 15px !important;
    line-height: 20px !important;
  }
  .owner-info-value {
    font-size: 10px !important;
    line-height: 15px !important;
  }
  .line-separator {
    margin: 8px 16px 0 !important;
  }
  .owner-section {
    .owner-info-after {
      & svg {
        width: 6.5rem !important;
        height: 2rem;
      }
    }
  }
  .skeleton-title {
    width: 160px;
    height: 24px;
    margin: 0 0 3.2px 0 !important;
  }
  .skeleton-text {
    height: 10px;
    min-width: 80px;
    margin: 2.4px 0 0 0 !important;
  }
  .skeleton-info-text {
    height: 10px;
    min-width: 200px;
  }
  .skeleton-details-text {
    height: 18px;
  }
  .skeleton-kbar-image {
    height: 200px;
    min-width: 150 !important;
  }
  .sub-container {
    min-width: 600px;
  }
  .declaimer-container {
    display: flex;
    padding: 10px 15px;
    & p {
      font-size: 11px;
      line-height: 16px;
    }
  }
}
@media screen and (min-width: 431px) and (max-width: 550px) {
  .container {
    align-items: start !important;
  }
  .certificate-section {
    margin: 0 !important;
    width: 95% !important;
  }
  .sub-container {
    min-width: 430px !important;
  }

  .certificate-section2 .goldbar-image-section img {
    width: 90% !important;
  }
  .title {
    font-size: 30.4px !important;
  }
  .certificate-value {
    font-size: 12px !important;
    line-height: 16px !important;
  }
  label {
    font-size: 12px !important;
    line-height: 16px !important;
  }
  .owner-info-value {
    font-size: 8px !important;
    line-height: 12px !important;
  }
  .line-separator {
    margin: 8px 16px 0 !important;
  }
  .owner-section {
    .owner-info-after {
      & svg {
        width: 5.5rem !important;
        height: 1.6rem;
      }
    }
  }
  .skeleton-title {
    width: 160px;
    height: 24px;
    margin: 0 0 3.2px 0 !important;
  }
  .skeleton-text {
    height: 10px;
    min-width: 80px;
    margin: 2.4px 0 0 0 !important;
  }

  .skeleton-info-text {
    height: 10px;
    min-width: 200px;
  }
  .skeleton-details-text {
    height: 18px;
  }
  .skeleton-kbar-image {
    height: 200px;
    min-width: 150 !important;
  }
  .sub-container {
    min-width: 600px;
  }
  .declaimer-container {
    display: flex;
    padding: 10px 10px;
    & p {
      font-size: 8px;
      line-height: 12px;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 430px) {
  .container {
    align-items: start !important;
  }
  .certificate-section {
    margin: 0 !important;
    width: 95% !important;
  }
  .sub-container {
    min-width: 330px !important;
  }

  .certificate-section2 .goldbar-image-section img {
    width: 90% !important;
  }
  .title {
    font-size: 16px !important;
  }
  .certificate-value {
    font-size: 8px !important;
    line-height: 10px !important;
    padding: 3.2px 0 0 0 !important;
  }
  label {
    font-size: 8px !important;
    line-height: 10px !important;
    padding: 3.2px 0 0 0 !important;
  }
  .owner-info-value {
    font-size: 6px !important;
    line-height: 8px !important;
    padding: 0 !important;
  }
  .line-separator {
    margin: 4px 16px 0 !important;
  }
  .owner-section {
    .owner-info-after {
      & svg {
        width: 4rem !important;
        height: 1rem;
      }
    }
  }
  .skeleton-title {
    width: 112px;
    height: 12px;
    margin: 0 0 3.2px 0 !important;
  }
  .skeleton-text {
    max-height: 4px !important;
    width: 100px;
    min-width: 80px;
    margin: 3.2px 0 0 0 !important;
  }

  .skeleton-info-text {
    height: 9px !important;
    min-width: 130px;
    margin: 0 0 4px 0 !important;
  }
  .skeleton-details-text {
    height: 16px;
  }
  .skeleton-kbar-image {
    height: 150px !important;
  }
  .sub-container {
    min-width: 325px;
  }
  .declaimer-container {
    display: flex;
    padding: 8px 10px;
    & p {
      font-size: 6px;
      line-height: 10px;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 330px) {
  .container {
    align-items: start !important;
  }
  .certificate-section {
    margin: 0 !important;
    width: 95% !important;
  }
  .owner-section {
    padding-right: 0% !important;
  }
  .owner-section .owner-info-after {
    padding-left: 0px !important;
  }
  .declaimer-container {
    display: flex;
    padding: 8px 10px;
    & p {
      font-size: 6px;
      line-height: 10px;
    }
  }
}
